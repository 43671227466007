// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-invierte-jsx": () => import("./../../../src/pages/invierte.jsx" /* webpackChunkName: "component---src-pages-invierte-jsx" */),
  "component---src-pages-nosotros-angela-jsx": () => import("./../../../src/pages/nosotros/angela.jsx" /* webpackChunkName: "component---src-pages-nosotros-angela-jsx" */),
  "component---src-pages-nosotros-experiencia-jsx": () => import("./../../../src/pages/nosotros/experiencia.jsx" /* webpackChunkName: "component---src-pages-nosotros-experiencia-jsx" */),
  "component---src-pages-nosotros-filosofia-jsx": () => import("./../../../src/pages/nosotros/filosofia.jsx" /* webpackChunkName: "component---src-pages-nosotros-filosofia-jsx" */),
  "component---src-pages-nosotros-index-jsx": () => import("./../../../src/pages/nosotros/index.jsx" /* webpackChunkName: "component---src-pages-nosotros-index-jsx" */),
  "component---src-pages-nosotros-quienes-somos-jsx": () => import("./../../../src/pages/nosotros/quienes-somos.jsx" /* webpackChunkName: "component---src-pages-nosotros-quienes-somos-jsx" */),
  "component---src-pages-nosotros-simon-jsx": () => import("./../../../src/pages/nosotros/simon.jsx" /* webpackChunkName: "component---src-pages-nosotros-simon-jsx" */),
  "component---src-pages-politica-de-privacidad-jsx": () => import("./../../../src/pages/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-jsx" */),
  "component---src-pages-proyectos-gerencia-de-proyectos-jsx": () => import("./../../../src/pages/proyectos/gerencia-de-proyectos.jsx" /* webpackChunkName: "component---src-pages-proyectos-gerencia-de-proyectos-jsx" */),
  "component---src-pages-proyectos-gestion-de-tierras-jsx": () => import("./../../../src/pages/proyectos/gestion-de-tierras.jsx" /* webpackChunkName: "component---src-pages-proyectos-gestion-de-tierras-jsx" */),
  "component---src-pages-proyectos-index-jsx": () => import("./../../../src/pages/proyectos/index.jsx" /* webpackChunkName: "component---src-pages-proyectos-index-jsx" */),
  "component---src-pages-proyectos-insula-living-double-jsx": () => import("./../../../src/pages/proyectos/insula-living/double.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-double-jsx" */),
  "component---src-pages-proyectos-insula-living-hobbie-workspot-jsx": () => import("./../../../src/pages/proyectos/insula-living/hobbie-workspot.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-hobbie-workspot-jsx" */),
  "component---src-pages-proyectos-insula-living-index-jsx": () => import("./../../../src/pages/proyectos/insula-living/index.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-index-jsx" */),
  "component---src-pages-proyectos-insula-living-loft-jsx": () => import("./../../../src/pages/proyectos/insula-living/loft.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-loft-jsx" */),
  "component---src-pages-proyectos-insula-living-suites-jsx": () => import("./../../../src/pages/proyectos/insula-living/suites.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-suites-jsx" */),
  "component---src-pages-proyectos-insula-living-tipos-jsx": () => import("./../../../src/pages/proyectos/insula-living/tipos.jsx" /* webpackChunkName: "component---src-pages-proyectos-insula-living-tipos-jsx" */),
  "component---src-pages-proyectos-proyectos-propios-jsx": () => import("./../../../src/pages/proyectos/proyectos-propios.jsx" /* webpackChunkName: "component---src-pages-proyectos-proyectos-propios-jsx" */)
}

